import React from 'react';
import {connect} from 'react-redux';
import * as actionCreators from '../../../../redux-store/actions/index';

class Cookies extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         cookieStatus: ''
      };
   }

   divHandler() {
      if (this.props && this.state.cookieStatus === '') {
         if (this.props.cookieStatus === null) {
            this.setState({cookieStatus: 'awaiting decision'});
         } else {
            this.setState({cookieStatus: this.props.cookieStatus});
         }
      }
      if (this.state.cookieStatus === 'awaiting decision') {
         return (
            <div className="cookies sticky">
          <span className="marg-right">
            Diese Webseite verwendet Cookies. Wenn Sie diese Webseite nutzen,
            akzeptieren Sie die Verwendung von Cookies.
          </span>
               <button
                  className="btn btn-primary"
                  onClick={this.onAcceptClickHandler.bind(this)}
               >
                  OK
               </button>
            </div>
         );
      } else {
         return false;
      }
   }

   onAcceptClickHandler() {
      this.setState({cookieStatus: 'accepted'});
      this.props.acceptCookie('accepted');
   }

   render() {
      return <div>{this.divHandler()}</div>;
   }
}

const mapStateToProps = state => ({
   cookieStatus: state.cookie.cookieStatus
});

const mapDispatchToProps = dispatch => ({
   acceptCookie: cookieStatus =>
      dispatch(actionCreators.acceptCookie(cookieStatus))
});

export default connect(mapStateToProps, mapDispatchToProps)(Cookies);
