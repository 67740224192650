import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as actionCreators from './../../../../redux-store/actions';
import Filter from '../../pages/SearchResult/components/Filter';
import Dropdown from '../dropDown/DropDown';
import ReactTooltip from 'react-tooltip';

let path = '/searchresult/';
let url = '';
let speedIndexValues = ['Alles', 'H', 'Q', 'R', 'T', 'V', 'W', 'Y'];
let loadIndexValues = ['Alles', '89', '91', '94', '98'];

class Tyresearch extends React.Component {
    constructor( props ) {
        super( props );
        let initialValues = {
            length: this.props.length,
            height: this.props.height,
            diameter: this.props.diameter,
            season: this.props.season,
            speed: this.props.speed,
            load: this.props.load,
            vehicleType: this.props.vehicleType
        };
        this.state = {
            radioClass: 'radiobutton',
            values: initialValues,
            searchString: '',
            filterSelections: this.props.filterSelections,
            computedValues: null,
            searchHider: 'search-hider hide',
            filterHider: 'filter-hider hide'
        };
        this.vehicleTypeHandler = this.vehicleTypeHandler.bind( this );
        this.seasonHandler = this.seasonHandler.bind( this );
        this.filterHandler = () => {
            if (this.state.filterHider == 'filter-hider show') {
                this.setState( { filterHider: 'filter-hider hide' } );
            } else {
                this.setState( { filterHider: 'filter-hider show' } );
            }
        };
        this.mobileHandler = () => {
            if (this.state.searchHider == 'search-hider show') {
                this.setState( { searchHider: 'search-hider hide' } );
            } else {
                this.setState( { searchHider: 'search-hider show' } );
            }
        };
        this.setSearchFlag = () => {
            this.props.loadTyres();
            setTimeout(()=>{ this.props.history.push(url)},1500);
            this.scrollToTop()
        };
        this.changeHandler = ( event, id ) => {
            this.props.onResetFilterSelections();
            if (
               event !== 'Bitte wählen' &&
               event !== 'Breite' &&
               event !== 'Höhe' &&
               event !== 'Zoll'
            ) {
                let newvalues = this.state.values;
                newvalues[id] = event.toString();
                if (id == 'length') {
                    this.props.onLengthChanged( event.toString() );
                    this.props.onHeightChanged( newvalues.height );
                    this.props.onDiameterChanged( newvalues.diameter );

                } else if (id == 'height') {
                    this.props.onHeightChanged( event.toString() );
                    this.props.onLengthChanged( newvalues.length );
                    this.props.onDiameterChanged( newvalues.diameter );

                } else if (id == 'diameter') {
                    this.props.onLengthChanged( newvalues.length );
                    this.props.onHeightChanged( newvalues.height );
                    this.props.onDiameterChanged( event.toString() );
                }
                if (id === 'speed') {
                    let _speed = event;
                    if (_speed === 'Alles') {
                        _speed = '';
                    }
                    this.props.onSpeedChanged( _speed );
                } else if (id === 'load') {
                    let _load = event;
                    if (_load === 'Alles') {
                        _load = '';
                    }
                    this.props.onLoadChanged( _load );
                }
            } else {
                return false;
            }
        };

    }

    componentDidMount() {

    }

    seasonHandler( event ) {
        this.props.onResetSetSearchFlag();
        this.props.onResetFilterSelections();
        let newvalues = this.state.values;
        newvalues.season = event.currentTarget.name;
        this.setState( { values: newvalues } );
        this.props.onSeasonChanged( event.currentTarget.name );
    };

    vehicleTypeHandler( event ) {
        this.props.onResetSetSearchFlag();
        this.props.onResetFilterSelections();
        let newvalues = this.state.values;
        newvalues.vehicleType = event.currentTarget.name;
        this.setState( { values: newvalues } );
        this.props.onVehicleTypeChanged( event.currentTarget.name );
    };

    componentWillMount() {
        this.createSearchString();
    }

    componentDidUpdate(prevProps,prevState) {

    }

    scrollToTop() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    iterateOverArray( value ) {
        if (this.props.tyreSizes) {
            if (this.props.tyreSizes !== '{}') {
                switch (value) {
                    case 'speed':
                        return (
                           <Dropdown
                              items={ speedIndexValues }
                              id="speed"
                              defaultValue={ this.props.speed }
                              onChangeCallBack={ this.changeHandler }
                              isLoading={ this.props.isLoading }
                           />
                        );
                        break;
                    case 'load':
                        return (
                           <Dropdown
                              items={ loadIndexValues }
                              id="load"
                              defaultValue={ this.props.load }
                              onChangeCallBack={ this.changeHandler }
                              isLoading={ this.props.isLoading }
                           />
                        );
                        break;
                }
            }
        }
    }

    createSelectOf( value ) {
        if (this.props.tyreSizes) {
            if (this.props.tyreSizes !== '{}') {
                switch (value) {
                    case 'length':
                        return (
                           <Dropdown
                              items={ Object.keys( this.props.tyreSizes ) }
                              id="length"
                              defaultValue={ this.state.values.length }
                              onChangeCallBack={ this.changeHandler }
                              isLoading={ this.props.isLoading }
                           />
                        );
                    case 'height':
                        let newvalues = this.state.values;
                        if (this.state.values.length !== '') {
                            let _heigth = this.props.tyreSizes[this.state.values.length];
                            /*TODO Fehlerhandling richtig einbauen*/
                            // height is set and exists NOT in result
                            if (
                               this.state.values.height !== '' &&
                               !(
                                  this.state.values.height in _heigth )
                            ) {
                                newvalues.height = Object.keys( _heigth )[0];
                            } else if (
                               Object.keys( _heigth ).length === 1 &&
                               Object.keys( _heigth )[0] !== this.state.values.height
                            ) {
                                // height isset and exits in result
                                newvalues.height = Object.keys( _heigth )[0];
                            }

                            if (
                               this.state.values.length !== '' &&
                               this.state.values.hewight === ''
                            ) {
                                newvalues.height = Object.keys( _heigth )[0];
                                // this.setState( { values: newvalues } );
                            }
                            if (this.props.height != newvalues.height) {
                                this.props.onHeightChanged( newvalues.height );
                            }

                            return (
                               <Dropdown
                                  defaultValueOnly={ true }
                                  items={ Object.keys( _heigth ) }
                                  id="height"
                                  defaultValue={ this.state.values.height }
                                  onChangeCallBack={ this.changeHandler }
                                  isLoading={ this.props.isLoading }
                               />
                            );
                        }
                        break;

                    case 'diameter':
                        if (
                           this.state.values.length !== '' &&
                           this.state.values.height !== ''
                        ) {
                            let newvalues = this.state.values;
                            let buffer2 = [];
                            let buffer = this.props.tyreSizes[this.state.values.length];
                            /*TODO Fehlerhandling richtig einbauen*/
                            buffer2 = buffer[this.state.values.height];
                            if (
                               buffer2.length === 1 &&
                               this.state.values.diameter !== buffer2[0]
                            ) {
                                newvalues.diameter = buffer2[0];
                            } else {
                                if (this.state.values.diameter !== '') {
                                    let exists = false;
                                    for (
                                       let i = 0;
                                       i < Object.keys( buffer[this.state.values.height] ).length;
                                       i++
                                    ) {
                                        if (this.state.values.diameter === buffer2[i]) {
                                            exists = true;
                                        }
                                    }
                                    if (!exists) {
                                        newvalues.diameter = buffer2[0];
                                    }
                                } else if (buffer2.length > 1) {
                                    newvalues.diameter = buffer2[0];
                                }
                            }
                            if (this.props.diameter != newvalues.diameter) {
                                this.props.onDiameterChanged( newvalues.diameter );
                            }

                            return (
                               <Dropdown
                                  defaultValueOnly={ true }
                                  items={ buffer[this.state.values.height] }
                                  onChangeCallBack={ this.changeHandler }
                                  defaultValue={ this.state.values.diameter }
                                  id="diameter"
                                  isLoading={ this.props.isLoading }
                               />
                            );
                        }
                        break;
                }
            }
        }
    }

    createSearchString() {
        let compValues = null;
        let filterValues = this.props.filterSelections;
        compValues = {
            ...this.state.values,
            filterValues
        };
        this.state.computedValues = compValues;
        let newbuffer = Buffer.from(
           JSON.stringify( this.state.computedValues )
        )
        .toString( 'base64' );
        let newUrlValue = path + newbuffer;
        this.props.onComputedUrlValue( newUrlValue );

        let newsearchString;
        if (newbuffer !== this.state.searchString) {
            newsearchString = newbuffer;
            url = path + this.state.searchString;
            // url im store speichern
            this.props.onComputedUrlValue( newUrlValue );

            this.setState( { searchString: newsearchString } );
        } else {
            url = path + this.state.searchString;
            this.props.onComputedUrlValue( newUrlValue );
        }
    }

    render() {
        this.createSearchString();
        return (
           <div className="tyreSearch-wrapper">
               { this.props.tyreSearch === 'home' ? (
                  <div className="tyresearch-border">
                      <h2 className="text-highlight marg-bot"> REIFENSUCHE </h2>
                      <div className="row">
                          <div className="col d-none d-md-block">
                              <img
                                 className="tyre-search-image img-fluid"
                                 src="/reifen.png"
                                 hspace="24"
                              />
                          </div>
                      </div>
                      <div className="row ">
                          <div className="col-12 col-md-6 col-xl-6">
                              <b className="text-highlight"> Fahrzeugart</b>
                              <div className="radio-button-wrapper">
                                  <button type="radio"
                                          className={ 'radio-button ' + ( this.props.vehicleType === 'pkw' ? 'active' : '' ) }
                                          onClick={ this.vehicleTypeHandler }
                                          name="pkw">
                                      PKW
                                  </button>
                                  <button type="radio"
                                          className={ 'radio-button ' + ( this.props.vehicleType === 'lkw' ? 'active' : '' ) }
                                          onClick={ this.vehicleTypeHandler }
                                          name="lkw">
                                      LKW
                                  </button>
                                  <button type="radio"
                                          className={ 'radio-button ' + ( this.props.vehicleType === 'suv' ? 'active' : '' ) }
                                          onClick={ this.vehicleTypeHandler }
                                          name="suv">
                                      SUV/4x4
                                  </button>
                              </div>
                          </div>

                          <div className="col-12 col-md-6 col-xl-6">
                              <b className="text-highlight"> Saison</b>
                              <div className="radio-button-wrapper">
                                  <button type="radio"
                                          className={ 'radio-button ' + ( this.props.season === 'summer' ? 'active' : '' ) }
                                          onClick={ this.seasonHandler }
                                          name="summer"
                                          data-tip data-for={ 'summer' }>
                                      <span className={ 'icon-sonne icon-style' }/>
                                      <ReactTooltip id='summer'>
                                            <span>
                                               Sommer
                                            </span>
                                      </ReactTooltip>
                                  </button>
                                  <button type="radio"
                                          className={ 'radio-button ' + ( this.props.season === 'winter' ? 'active' : '' ) }
                                          onClick={ this.seasonHandler }
                                          name="winter"
                                          data-tip data-for={ 'winter' }>
                                      <span className={ 'icon-Schnee icon-style' }/>
                                      <ReactTooltip id='winter'>
                                            <span>
                                               Winter
                                            </span>
                                      </ReactTooltip>
                                  </button>
                                  <button type="radio"
                                          className={ 'radio-button ' + ( this.props.season === 'all' ? 'active' : '' ) }
                                          onClick={ this.seasonHandler }
                                          name="all"
                                          data-tip data-for={ 'all' }>
                                      <span className={ 'icon-allwetter icon-style' }/>
                                      <ReactTooltip id='all'>
                                            <span>
                                               Allwetter
                                            </span>
                                      </ReactTooltip>
                                  </button>
                              </div>
                          </div>
                      </div>

                      <div className="row">
                          <div className="col-xl-6 col-md-12 col-12 padd-top">
                              <b className={ 'text-highlight' }>Reifengröße</b>
                              <div>
                                  <div className="custom-dd tire-size-dd">
                                      { this.createSelectOf( 'length' ) }
                                  </div>
                                  <div className="custom-dd tire-size-dd">
                                      { this.createSelectOf( 'height' ) }
                                  </div>
                                  <div className="custom-dd tire-size-dd">
                                      { this.createSelectOf( 'diameter' ) }
                                  </div>
                              </div>
                          </div>
                          <div className="col-12 col-md-12 col-xl-6 padd-top home">
                              <div className="half-width">
                                  <b className={ 'text-highlight' }>Last</b>
                                  <div className="custom-dd load-speed">
                                      { this.iterateOverArray( 'load' ) }
                                  </div>
                              </div>
                              <div className="half-width">
                                  <b className={ 'text-highlight' }>Geschwindigkeit</b>
                                  <div className="custom-dd load-speed">
                                      { this.iterateOverArray( 'speed' ) }
                                  </div>
                              </div>
                          </div>
                      </div>

                      {/*<div className="row">*/ }
                      {/*    <div className={ 'col-11 line-tyre-search-home' }/>*/ }
                      {/*</div>*/ }
                      <div className="row">
                          <div className="col">
                              <Link to={ url }>
                                  <button className="btn btn-success full-width">Suchen</button>
                              </Link>
                          </div>
                      </div>
                  </div>
               ) : (
                  <div>
                      <div className="row mobile-margin">
                          <div className="col d-xl-none">
                              <button className="btn btn-outline-primary full-width" onClick={ () => {
                                  this.mobileHandler();
                              } }>
                                  Suche anpassen...
                              </button>
                          </div>
                          <div className="col d-xl-none">
                              <button className="btn btn-outline-primary full-width" onClick={ () => {
                                  this.filterHandler();
                              } }>
                                  Suche filtern...
                              </button>
                          </div>
                      </div>
                      <div className={ this.state.searchHider }>
                          <div className="row ">
                              <div className="col-12">
                                  <div className="search-title"> Fahrzeugart</div>
                                  <div className="radio-button-wrapper">
                                      <button type="radio"
                                              className={ 'radio-button ' + ( this.props.vehicleType === 'pkw' ? 'active' : '' ) }
                                              onClick={ this.props.isLoading ? this.doNothing : this.vehicleTypeHandler }
                                              name="pkw">
                                          PKW
                                      </button>
                                      <button type="radio"
                                              className={ 'radio-button ' + ( this.props.vehicleType === 'lkw' ? 'active' : '' ) }
                                              onClick={ this.props.isLoading ? this.doNothing : this.vehicleTypeHandler }
                                              name="lkw">
                                          LKW
                                      </button>
                                      <button type="radio"
                                              className={ 'radio-button ' + ( this.props.vehicleType === 'suv' ? 'active' : '' ) }
                                              onClick={ this.props.isLoading ? this.doNothing : this.vehicleTypeHandler }
                                              name="suv">
                                          SUV/4x4
                                      </button>
                                  </div>
                              </div>
                          </div>
                          <div className="row ">
                              <div className="col-12">
                                  <div className="search-title"> Saison</div>
                                  <div className="radio-button-wrapper">
                                      <button type="radio"
                                              className={ 'radio-button ' + ( this.props.season === 'summer' ? 'active' : '' ) }
                                              onClick={ this.props.isLoading ? this.doNothing : this.seasonHandler }
                                              name="summer"
                                              data-tip data-for={ 'summer' }>
                                          <span className={ 'icon-sonne icon-style' }/>
                                          <ReactTooltip id='summer'>
                                                <span>
                                                   Sommer
                                                </span>
                                          </ReactTooltip>
                                      </button>
                                      <button type="radio"
                                              className={ 'radio-button ' + ( this.props.season === 'winter' ? 'active' : '' ) }
                                              onClick={ this.props.isLoading ? this.doNothing : this.seasonHandler }
                                              name="winter"
                                              data-tip data-for={ 'winter' }>
                                          <span className={ 'icon-Schnee icon-style' }/>
                                          <ReactTooltip id='winter'>
                                                <span>
                                                   Winter
                                                </span>
                                          </ReactTooltip>
                                      </button>
                                      <button type="radio"
                                              className={ 'radio-button ' + ( this.props.season === 'all' ? 'active' : '' ) }
                                              onClick={ this.props.isLoading ? this.doNothing : this.seasonHandler }
                                              name="all"
                                              data-tip data-for={ 'all' }>
                                          <span className={ 'icon-allwetter icon-style' }/>
                                          <ReactTooltip id='all'>
                                                <span>
                                                   Allwetter
                                                </span>
                                          </ReactTooltip>
                                      </button>
                                  </div>
                              </div>
                          </div>


                          <div className="row">
                              <div className="col-12">
                                  <div className="search-title"> Reifengröße</div>
                                  <div className="select-buttons-tire-size-result">
                                      <div className="custom-dd tire-size-dd">
                                          { this.createSelectOf( 'length' ) }
                                      </div>
                                      <div className="custom-dd tire-size-dd">
                                          { this.createSelectOf( 'height' ) }
                                      </div>
                                      <div className="custom-dd tire-size-dd">
                                          { this.createSelectOf( 'diameter' ) }
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="row">
                              <div className="col-12 col-xl-12 col-sm-6 col-xs-6">
                                  <div className="search-title-loadIndex"> Last</div>
                                  <div className={ 'select-buttons-tire-size-result' }>
                                      <div className="custom-dd load-speed">
                                          { this.iterateOverArray( 'load' ) }
                                      </div>
                                  </div>
                              </div>
                              <div className="col-12 col-xl-12 col-sm-6 col-xs-6">
                                  <div className="search-title"> Geschwindigkeit</div>
                                  <div className={ 'select-buttons-tire-size-result' }>
                                      <div className="custom-dd load-speed">
                                          { this.iterateOverArray( 'speed' ) }
                                      </div>
                                  </div>
                              </div>
                          </div>
                          {/*<div className="row">*/ }
                          {/*    <div className="col-12">*/ }
                          {/*        <div className={ 'select-buttons-tire-size-result' }>*/ }
                          {/*            <div className="custom-dd load-speed">*/ }
                          {/*                { this.iterateOverArray( 'speed' ) }*/ }
                          {/*            </div>*/ }

                          {/*        </div>*/ }
                          {/*    </div>*/ }
                          {/*</div>*/ }

                          <div className="row">
                              <div className="col">
                                  <button onClick={ ()=>this.setSearchFlag() }
                                          className="btn btn-primary full-width"
                                          disabled={ this.props.isLoading }>
                                      Übernehmen
                                  </button>
                              </div>
                          </div>

                      </div>
                      <div className={ this.state.filterHider }>
                          <Filter values={ this.state.values }/>
                      </div>
                  </div>
               ) }
           </div>
        );
    }
}

const mapStateToProps = state => ( {
    tyreSizes: state.sizes,
    filterSelections: state.routeChanged.filterSelections,
    url: state.routeChanged.url,
    isLoading: state.tyres.isLoading,
    changeFlag: state.routeChanged.changeFlag,
    season: state.routeChanged.season,
    vehicleType: state.routeChanged.vehicleType,
    speed: state.routeChanged.speed,
    load: state.routeChanged.load,
    length: state.routeChanged.length,
    height: state.routeChanged.height,
    diameter: state.routeChanged.diameter
} );

const mapDispatchToProps = dispatch => {
    return {
        onChangeRouteFrom: from => dispatch( actionCreators.changeRouteFrom( from ) ),
        onChangeRouteTo: to => dispatch( actionCreators.changeRouteTo( to ) ),
        onComputedUrlValue: val => dispatch( actionCreators.newComputedUrlValue( val ) ),
        onResetFilterSelections: () => dispatch( actionCreators.resetFilterSelections() ),
        onResetSetSearchFlag: () => dispatch( actionCreators.resetSearchFlag() ),
        onSetSearchFlag: () => dispatch( actionCreators.changeFlag() ),
        onSetPage: page => actionCreators.setPage( page ),
        onSeasonChanged: season => dispatch( actionCreators.seasonChanged( season ) ),
        onVehicleTypeChanged: vehicleType => dispatch( actionCreators.vehicleTypeChanged( vehicleType ) ),
        onSpeedChanged: speed => dispatch( actionCreators.speedChanged( speed ) ),
        onLoadChanged: load => dispatch( actionCreators.loadChanged( load ) ),
        onLengthChanged: length => dispatch( actionCreators.lengthChanged( length ) ),
        onHeightChanged: height => dispatch( actionCreators.heightChanged( height ) ),
        onDiameterChanged: diameter => dispatch( actionCreators.diameterChanged( diameter ) ),
        loadTyres: () => dispatch(actionCreators.loadTyres())

    };
};

export default connect(
   mapStateToProps,
   mapDispatchToProps
)( Tyresearch );
