import React from 'react';

import OpeningTimes from '../openingTimes/openingTimes';
import { Link } from 'react-router-dom';

let i;
let arr = [];
const data = {
    address: [],
    counter: 0
};
let buff = -1;
let markerPriority = false;

class Address extends React.Component {
    constructor( props ) {
        super( props );
        this.state = { counter: 0 };
    }

    openingTimesHider( e ) {
        markerPriority = true;
        const newstate = this.state;
        newstate.counter = e.currentTarget.id;
        data.counter = e.currentTarget.id;
        this.setState( newstate );
        this.props.callBackFromParent( data );
    }

    contactCardsActiveHandler( ii ) {
        let clsnm = 'contact-cards';
        if (this.state.counter == ii) {
            clsnm = 'contact-cards active';
        }
        return clsnm;
    }

    contactPageBuilder() {
        arr = [];

        if (this.props && this.props.firmData && this.props.firmData[0] && this.props.firmData[0].AllgemeineDaten) {
            if (this.props.mapCounter != this.state.counter) {
                if (markerPriority === false) {
                    buff = this.state.counter;
                    this.setState( { counter: this.props.mapCounter } );
                } else {
                    markerPriority = false;
                    buff = this.props.mapCounter;
                }
            }

            let ii = 0;
            let keys = 1;

            if (this.props && this.props.hauptsitz !== true) {
                keys = Object.keys( this.props.firmData ).length;
            }
            while (ii < keys) {
                let Clss = 'hidden';
                if (this.state.counter == ii && this.props.openingTimesCount !== 'hidden') {
                    Clss = 'visible';
                }
                i = this.props.firmData[ii].AllgemeineDaten;
                data.address[ii] = ( `${ i.Strasse } , ${ i.Plz } , ${ i.Ort } , ${ i.Land }` );
                arr[ii] = (

                    <div className={ `${ 'row' + ' ' }${ this.contactCardsActiveHandler( ii ) }` } key={ ii } id={ ii }
                         onClick={ this.openingTimesHider.bind( this ) }>
                        <span className="contact-info-name-highlight">{ i.Name }</span>
                        <div className="row">
                            <div className={( Clss == 'visible' ) ?" col-6" : "col-12"}>
                                <br/>
                                <span className="contact-info-text-bold"> Adresse</span><br/>
                                <span className="contact-info-text"> { i.Strasse } </span> <br/>
                                <span className="contact-info-text"> { i.Plz } { i.Ort } </span> <br/>
                                { ( () => {
                                    if (i.Land === 'DE') {
                                        return <span className="contact-info-text"> Deutschland </span>;
                                    }
                                } )() }
                                <br/>
                                <span className="contact-info-text-bold"> Telefon: </span> <span className="contact-info-text"> { i.Telefon } </span>
                                <br/>
                                <span className="contact-info-text-bold"> E-Mail:  </span> <span className="contact-info-text"> { i.Email } </span>

                            </div>
                            <br/>
                            { ( Clss == 'visible' ) ?
                                <div className="col-6">
                                    <br/>
                                    <span className="contact-info-text-bold">Öffnungszeiten: </span>
                                    <div className="">
                                        <OpeningTimes data={ this.props.firmData } vendorNo={ ii }/>
                                        <br/>
                                    </div>
                                    { this.props.isCheckout === true ?
                                        <div>
                                            <button onClick={ this.props.navigationCallBack } className="btn btn-outline-success"> Auswahl</button>
                                        </div>
                                        : ''
                                    }
                                </div>
                                : ''
                            }
                        </div>
                    </div>
                );
                ii++;
            }
            buff = this.state.counter;
            return arr;
        }
        return ( <span>Konnte keine Händlerdaten finden</span> );
    }

    render() {
        return (
            <div>
                { this.contactPageBuilder() }
            </div>
        );
    }
}

export default Address;
