import React from 'react';
import {connect} from 'react-redux';
import Helmet from 'react-helmet';
import * as actionCreators from '../../../../redux-store/actions';
import Tyresearch from '../../shared/tyresearch/Tyresearch';
import axios from "axios";

class Home extends React.Component {
   constructor(props) {
      super(props);
   }

   componentDidMount() {
      this.props.onSetNavigation('/');
      this.props.onResetTermsOfUse();
      this.props.onResetDataProtection();
   }

   showDebitorData() {
      let i = 0;
      let countDebitor = 0;
      let firmDataArray = [];
      (this.props.firmData ?
         countDebitor = Object.keys(this.props.firmData).length
         :
         <div>
            Keine Händlerdaten gefunden.
         </div>);

      if (countDebitor > 1) {
         while (i < countDebitor) {
            let firmdata = this.props.firmData[i].AllgemeineDaten;
            firmDataArray.push(
               <div key={firmdata.Name + i} className="contact-card-entry text-center">
                  <div>
                     <b>
                        <span>Standort {firmdata.Plz}</span>
                        <span> {firmdata.Ort}</span>
                     </b>
                  </div>
                  <div>
                     <span>{firmdata.Strasse}</span>
                  </div>
                  <div>
                     <span><i className="fas fa-phone-alt"></i> {firmdata.Telefon}</span>
                  </div>
               </div>
            );
            i++;
         }
      } else {
         let firmdata = this.props.firmData[0].AllgemeineDaten;
         let openingTimes = this.props.firmData[0].openingTimes.replace('Mo', '')
         .replace('Di', '')
         .replace('Mi', '')
         .replace('Do', '')
         .replace('Fr', '')
         .replace('Sa', '');
         openingTimes = openingTimes.replace(/0 1/g, '0 & 1');
         openingTimes = openingTimes.split('\n');
         openingTimes.shift();
         firmDataArray.push(
            <div key={firmdata.Name + i} className="contact-card-entry text-left">
               <div className={'one-debitor'}>
                  <div>
                     <span>{firmdata.Strasse}</span>
                  </div>
                  <div>
                     <span> {firmdata.Plz}</span>
                     <span> {firmdata.Ort}</span>
                  </div>
               </div>
               <hr/>
               <div>
                  <span className={'contact-card-title'}> <i className="fas fa-phone-alt"/> {firmdata.Telefon} </span>
                  <br/>
                  <br/>
               </div>
               <div>
                  <span className={'contact-card-title'}> <i className="far fa-envelope"/> {firmdata.Email} </span>
               </div>
               <div>
                  <span className={'contact-card-title'}> <i className="far fa-clock"/> Öffnungszeiten </span>
                  <div className={'opening-times'}>
                     <div className="row">
                        <div className="col-4">
                           Montag
                        </div>
                        <div className="col-8">
                           {openingTimes[0]}
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-4">
                           Dienstag
                        </div>
                        <div className="col-8">
                           {openingTimes[1]}
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-4">
                           Mittwoch
                        </div>
                        <div className="col-8">
                           {openingTimes[2]}
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-4">
                           Donnerstag
                        </div>
                        <div className="col-8">
                           {openingTimes[3]}
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-4">
                           Freitag
                        </div>
                        <div className="col-8">
                           {openingTimes[4]}
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-4">
                           Samstag
                        </div>
                        <div className="col-8">
                           {openingTimes[5]}
                        </div>
                     </div>
                  </div>
               </div>

            </div>
         );
      }

      return (
         <div className={'contact-card '}>
            <div
               className={'contact-card-name ' + (countDebitor > 1 ? ' text-center' : 'name-size text-left')}>{this.props.firmData[0].AllgemeineDaten.Name}</div>
            {firmDataArray}
         </div>
      );
   };

   render() {
      let i = 0;
      return (
         <div className="home-wrapper">
            <Helmet>
               <title>Startseite</title>
            </Helmet>
            <div className="container">
               <div className="row">
                  <div className="col-md-8 col-xl-8">
                     <Tyresearch
                        location={this.props.history.location}
                        tyreSearch={'home'}
                     />
                  </div>
                  <div className="col-md-8 col-xl-4 col-sm-12 col-xs-12">
                     {this.showDebitorData()}
                  </div>
               </div>
            </div>
         </div>
      );
   }
}

const mapStateToProps = state => ({
   scTires: state.shoppingcart.shoppingCartTyres,
   timestamp: state.shoppingcart.timestamp,
   firmData: state.firmData
});

const mapDispatchToProps = {
   onSetNavigation: nav => actionCreators.setNavigation(nav),
   onResetTermsOfUse: () => actionCreators.resetTermsOfUse(),
   onResetDataProtection: () => actionCreators.resetDataProtection(),
   onCreateTimeStamp: () => actionCreators.createTimeStamp()
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
